import React from "react";
import { graphql } from "gatsby";
import { Hero, ImageAndContentBlock } from "components";
import Previewable from "../components/Previewable";
import SEO from "../components/SEO";
import RelatedProjects from "../components/Related/RelatedProjects";
import { RenderShortcode } from "../components/RenderShortcode";
import "./post.scss";
import { decodeEntities, stripHtmlTags } from "../utils/helpers";

const BlogPost = (props) => {
  const { data, location } = props;
  const { wordpressPost: post, site, siteSettings, wordpressWpSettings } = data;
  const { wordpressUrl } = wordpressWpSettings;
  const {
    options: { projectFooterContent, projectFooterImage },
  } = siteSettings;
  if (!post) return null;
  const {
    title,
    content,
    excerpt,
    featured_media,
    yoast,
    categories,
    date,
    author,
  } = post;
  const { blogSlug, date_format } = wordpressWpSettings;
  return (
    <>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(
          wordpressWpSettings.title
        )}`}
        desc={excerpt ? stripHtmlTags(excerpt) : null}
        yoast={yoast}
        banner={
          featured_media &&
          featured_media.source_url &&
          featured_media.source_url
        }
        location={location}
      />
      <Hero heading={title} eyebrow="Blog" isCentered />
      <section className="single-post layout">
        <div className="single-item-container">
          <RenderShortcode content={content} />
        </div>
      </section>
      <div className="single-item-footer">
        <div className="single-item-footer-container">
          <RelatedProjects title="Related" fullWidth />
          <ImageAndContentBlock
            content={projectFooterContent}
            image={projectFooterImage}
          />
        </div>
      </div>
    </>
  );
};

export default Previewable(BlogPost, "post");

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date
    title
  }
  query BlogPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
      date_format
    }
    siteSettings: wordpressAcfOptions {
      options {
        showAuthor
        customCss
        projectFooterContent
        projectFooterImage {
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 600, quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      path
      content
      excerpt
      wordpress_id
      date
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 670, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      categories {
        name
        slug
        path
      }
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
        opengraph_image {
          source_url
        }
        twitter_image {
          source_url
        }
      }
    }
  }
`;
